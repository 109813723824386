<template>
  <b-card
    no-body
    class="mb-1"
  >
    <b-card-body>
      <b-row>
        <template v-if="isAdmin">
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('supplier') }}</label>
            <LegalSelect v-model="localLegals" />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('supplierCountry') }}</label>
            <CountrySelect v-model="localCountries" />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('status') }}</label>
            <multiselect
              v-model="localStatuses"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              label="label"
              track-by="value"
              :multiple="true"
              :options="statusesOptions"
            />
          </b-col>
        </template>
        <template v-else>
          <b-col
            cols="12"
            md="6"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('supplierCountry') }}</label>
            <CountrySelect v-model="localCountries" />
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('status') }}</label>
            <multiselect
              v-model="localStatuses"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              label="label"
              track-by="value"
              :multiple="true"
              :options="statusesOptions"
            />
          </b-col>
        </template>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCol, BRow,
} from 'bootstrap-vue';
import { offerStatuses } from '@/constants/offers.js';
import CountrySelect from '@/views/apps/components/CountrySelect.vue';
import LegalSelect from '@/views/apps/components/LegalSelect.vue';

export default {
  name: 'TradingOfferFilter',
  components: {
    LegalSelect,
    CountrySelect,
    BCard,
    BRow,
    BCol,
    BCardBody,
  },
  props: {
    legals: {
      type: Array,
    },
    countries: {
      type: Array,
    },
    statuses: {
      type: Array,
    },
  },
  data() {
    return {
      statusesOptions: Object.entries(offerStatuses).map(([value, { name: label }]) => ({ value, label })),
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters['profile/isAdmin'];
    },
    localLegals: {
      get() {
        return this.legals;
      },
      set(val) {
        this.$emit('update:legals', val);
      },
    },
    localCountries: {
      get() {
        return this.countries;
      },
      set(val) {
        this.$emit('update:countries', val);
      },
    },
    localStatuses: {
      get() {
        return this.statuses;
      },
      set(val) {
        this.$emit('update:statuses', val);
      },
    },
  },
};
</script>
