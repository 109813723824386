<template>
  <div>
    <TradingOfferFilter
      :legals.sync="supplierFilter"
      :countries.sync="supplierCountriesFilter"
      :statuses.sync="statusFilter"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('showEntriesQty') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              variant="primary"
              :to="{ name: 'trading-offers-add', params: { id: 'new' } }"
            >
              {{ $t('addOffer') }}
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refOfferListTable"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :busy="isLoading"
        :sort-by.sync="sortBy"
        :sort-compare="sortCompare"
        show-empty
        :empty-text="$t('noMatchingText')"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
        <!-- Loader -->
        <template #table-busy>
          <table-loader />
        </template>

        <!-- Column: Id -->
        <template #cell(id)="data">
          <p class="mb-0">
            {{ data.item.id }}
          </p>
        </template>

        <!-- Column: Date -->
        <template #cell(date)="data">
          <p class="mb-0">
            {{ resolveDate(data.item.created_at) }}
          </p>
          <p class="mb-0">
            {{ resolveDate(data.item.valid_until) }}
          </p>
        </template>

        <!-- Column: Supplier -->
        <template #cell(supplier)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="getOfferInfo(data.item).supplier.avatar_url"
                :text="avatarText(getOfferInfo(data.item).supplier.name)"
                variant="light-primary"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ getOfferInfo(data.item).supplier.companyName }}
            </span>
            <small class="text-muted">
              {{ getOfferInfo(data.item).supplier.country }}
            </small>
          </b-media>
        </template>

        <!-- Column: Product -->
        <template #cell(product)="data">
          <b-media
            class="product-column"
            vertical-align="center"
          >
            <template #aside>
              <b-img
                size="32"
                rounded
                style="width: 32px"
                :src="getOfferInfo(data.item).productImage"
                variant="light-primary"
              />
            </template>
            <div class="d-flex">
              <h6
                class="text-nowrap product-column-name"
              >
                {{ getOfferInfo(data.item).productName }}
              </h6>
              <h6>
                {{ getOfferInfo(data.item).productCount }}
              </h6>
            </div>
            <small class="text-muted">
              {{ getOfferInfo(data.item).productIndex }}
            </small>
          </b-media>
        </template>

        <!-- Column: Price -->
        <template #cell(price)="data">
          <b-media vertical-align="center">
            <template
              v-if="isAdmin"
              #aside
            >
              <b-avatar
                :id="`offer-row-${data.item.id}`"
                size="32"
                :variant="`light-${resolveOfferStatusVariantAndIcon(data.item.status).variant}`"
              >
                <feather-icon
                  :icon="resolveOfferStatusVariantAndIcon(data.item.status).icon"
                />
              </b-avatar>
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.currency | currencySymbol }}{{ data.item.price }}
            </span>
            <small
              v-if="isAdmin"
              class="text-muted"
            >
              {{ resolveOfferStatusVariantAndIcon(data.item.status).status }}
            </small>
          </b-media>
        </template>

        <!-- Column: Buyer -->
        <template #cell(buyer)="data">
          <b-media
            v-if="getOfferInfo(data.item).buyer.exists"
            vertical-align="center"
          >
            <template #aside>
              <b-avatar
                size="32"
                :src="getOfferInfo(data.item).buyer.avatar_url"
                :text="avatarText(getOfferInfo(data.item).buyer.name)"
                variant="light-primary"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ getOfferInfo(data.item).buyer.companyName }}
              <b-link
                v-if="data.item.request"
                class="alert-link"
                :to="{ name: 'trading-requests-preview', params: {id: data.item.request.id}}"
              >
                R #{{ data.item.request.id }}
              </b-link>
            </span>
            <small class="text-muted">
              {{ getOfferInfo(data.item).buyer.country }}
            </small>
          </b-media>
        </template>

        <!-- Column: Offer Status -->
        <template #cell(status)="data">
          <b-badge
            v-if="getOfferInfo(data.item).status.name"
            :variant="getOfferInfo(data.item).status.variant"
            class="px-1"
          >
            {{ getOfferInfo(data.item).status.name }}
          </b-badge>
        </template>

        <!-- Column: Client -->
        <template #cell(client)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar_url"
                :text="avatarText(data.item.client.name)"
                :variant="`light-${resolveClientAvatarVariant(data.item.offerStatus)}`"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.client.name }}
            </span>
            <small class="text-muted">
              {{ data.item.client.companyEmail }}
            </small>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-link
              :to="{ name: 'trading-offers-preview', params: { id: data.item.id } }"
            >
              <feather-icon
                :id="`offer-row-${data.item.id}-preview-icon`"
                icon="EyeIcon"
                size="16"
                class="mx-1"
              />
            </b-link>
            <b-tooltip
              title="Preview Offer"
              :target="`offer-row-${data.item.id}-preview-icon`"
            />

            <b-tooltip
              title="Preview Offer"
              :target="`offer-row-${data.item.id}-preview-icon`"
            />

            <b-link
              v-if="data.item.can_edit"
              :to="{ name: 'trading-offers-add', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
            </b-link>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{ $t('tableDetails', { from: dataMeta.from, to: dataMeta.to, of: dataMeta.of }) }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalOffers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BMedia, BAvatar, BButton, BLink,
  BPagination, BTooltip, BImg, BBadge,
} from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import vSelect from 'vue-select';
import { onMounted, ref } from '@vue/composition-api';
import TradingOfferFilter from '@/views/trading/trading-offers/TradingOfferFilter.vue';
import TableLoader from '@/views/tables/TableLoader.vue';
import useOffersList from './useOffersList';

export default {
  components: {
    TableLoader,
    TradingOfferFilter,
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    BButton,
    BPagination,
    BTooltip,
    BImg,
    BBadge,
    BLink,

    vSelect,
  },
  computed: {
    isAdmin() {
      return this.$store.getters['profile/isAdmin'];
    },
  },
  setup() {
    const {
      fetchOffers,
      tableColumns,
      perPage,
      items,
      currentPage,
      totalOffers,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refOfferListTable,

      supplierFilter,
      supplierCountriesFilter,
      statusFilter,
      verifiedFilter,

      sortCompare,

      getOfferInfo,
      resolveDate,
      resolveOfferStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useOffersList();

    const isLoading = ref(false);

    onMounted(async () => {
      isLoading.value = true;

      await fetchOffers().finally(() => {
        isLoading.value = false;
      });
    });

    return {
      fetchOffers,
      tableColumns,
      perPage,
      items,
      currentPage,
      totalOffers,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refOfferListTable,

      supplierFilter,
      supplierCountriesFilter,
      statusFilter,
      verifiedFilter,

      sortCompare,

      getOfferInfo,
      resolveDate,
      avatarText,
      resolveOfferStatusVariantAndIcon,
      resolveClientAvatarVariant,
      isLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.offer-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
.product-column {
  max-width: 300px;
  .media-body {
    max-width: 100%;
  }
  &-name {
    max-width: 70%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
